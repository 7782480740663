import React, { useEffect, useState } from 'react'
export const CurrentUserContext = React.createContext({})

export function CurrentUserProvider(props) {
  const [currentUser, setCurrentUser] = useState()
  const [currentUserIsSuperAdmin, setCurrentUserIsSuperAdmin] = useState(false)
  const [currentUserIsNotSuperAdmin, setCurrentUserIsNotSuperAdmin] = useState(false)
  const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState(false)
  const [currentUserIsAdminOrEditor, setCurrentUserIsAdminOrEditor] = useState(false)
  const [siteId, setSiteId] = useState()
  const [siteName, setSiteName] = useState()

  useEffect(() => {
    if(currentUser) {
      setCurrentUserIsSuperAdmin(currentUser.permissions[0].type === 'super_admin')
      setCurrentUserIsNotSuperAdmin(currentUser.permissions[0].type !== 'super_admin')
      setCurrentUserIsAdmin(currentUser.permissions[0].type.includes('admin'))
      setCurrentUserIsAdminOrEditor(['admin', 'editor'].includes(currentUser.permissions[0].type))
      setSiteId(currentUser.permissions[0].site_id)
      setSiteName(currentUser.permissions[0].site?.name)
    }
  }, [currentUser])

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser, currentUserIsAdmin, currentUserIsAdminOrEditor, currentUserIsSuperAdmin, siteId, currentUserIsNotSuperAdmin, siteName }}>
      {props.children}
    </CurrentUserContext.Provider>
  )
}
