import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { CurrentUserProvider } from '../contexts/CurrentUserContext'
import NotificationProvider from '../contexts/NotificationContext'
const MemberDashboard =   lazy(() => import('./member/Dashboard'))
const LandingPage =       lazy(() => import('./member/LandingPage'))
const LoginComponent =    lazy(() => import('./member/LoginComponent'))
const SetPassword =       lazy(() => import('./passwords/SetPassword'))
const ForgottenPassword = lazy(() => import('./passwords/ForgottenPassword'))
const ResetPassword =     lazy(() => import('./passwords/ResetPassword'))
import '../common/MobxConfig'

export default function RouterComponent({ mabani_site_url, unipod_site_url, cladtech_site_url }) {
  return (
    <NotificationProvider>
      <CurrentUserProvider>
        <Router>
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route exact path="/">
                <LandingPage mabani_site_url={mabani_site_url} unipod_site_url={unipod_site_url} cladtech_site_url={cladtech_site_url} />
              </Route>
              <Route path="/login">
                <LoginComponent />
              </Route>
              <Route path="/change-password/:token">
                <SetPassword />
              </Route>
              <Route path="/reset-password/:token">
                <ResetPassword />
              </Route>
              <Route path="/forgotten-password">
                <ForgottenPassword />
              </Route>
              <Route path="/member">
                <MemberDashboard />
              </Route>
              <Route path="/admin">
                <MemberDashboard />
              </Route>
              <Route path="/user">
                <MemberDashboard />
              </Route>
              <Route path="/editor">
                <MemberDashboard />
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </CurrentUserProvider>
    </NotificationProvider>
  )
}